.nk-landing{
    
    font-family: 'Nunito Sans', sans-serif;
    font-style:normal;    
    position:flex;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display:flex;
    flex-direction: column;

}

.nka-button.nk-size-l{
    padding-left:30px;
    padding-right:30px;
    min-width:15%;
}
.nk-landing-content{
    padding:30px;
    padding-top:10px;
}

.nk-landing h1 {    
    font-size:3em;
    color:#51a026;    
    overflow-wrap: break-word;
    font-weight:900;
}

.nk-landing-typography{
    font-size:1.8em;
    font-weight:900;
    width:100%;
}

.nk-landing-ingress{
    font-size:1.4em;
    font-weight:500;    
}

.nk-landing-top{
    padding:50px;
    padding-top:0;
    padding-bottom:0px;
    min-height:95px;
    display:flex;
    align-content: space-between;
    align-items:end;
}
.nk-landing-top *{
    align-self: flex-end;
}
.nk-landing-top .nk-spacer{
    flex:1;
}

.nk-landing-top {
    display:flex;
}

.nk-landing-top a{
    padding: 4.0625rem 1.0625rem .625rem;
    display: block;
    text-decoration: none;
    color: #2e2d30;
    font-size: 1.0625rem;
    font-weight: 800;
    letter-spacing: .01875rem;
    transition: padding .2s;
}

.nk-landing-top a:hover{
    color:white;
    background-color:#51a026;
}

.nk-landing-content{
    
    background-repeat: no-repeat;
    background-position: center right;
    background-size:60%;
    flex:1;
}

.img-resize {
    width:200px;
    height: auto;    
    margin-left: -40px;
    margin-bottom: -40px;    
}